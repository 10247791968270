<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถานศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="collegeAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="colleges"
          :search="search"
        >
        <template v-slot:item.collegeDev="{ item }">
            <div v-if="item.collegeDev === 'Dev'" class="text-center">              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon> พัฒนา
            </div>
          </template>



          <template v-slot:item.college_vecpro="{ item }">
            <div v-if="item.college_vecpro === '1'" class="text-center">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon> สอจ.
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="yellow" @click.stop="collegeEdit(item.college_ID)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon color="red" @click.stop="collegeDelete(item.college_ID)">
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addcollegedialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addcollegedialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลสถานศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addcollegeform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-text-field
                        label="รหัสสถานศึกษา"
                        v-model="addcollege.college_code"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสถานศึกษา"
                        v-model="addcollege.college_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ชื่อย่อสถานศึกษา"
                        v-model="addcollege.collegeIni"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-autocomplete
                        :items="veivocals"
                        item-text="veiVocalName"
                        item-value="veiVocalID"
                        label="สังกัดสถาบันการอาชีวศึกษา"
                        v-model="addcollege.collegeVei"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        label="จังหวัด"
                        v-model="addcollege.province_ID"
                        :items="provinces"
                        item-text="province_name"
                        item-value="province_ID"
                        required
                        :rules="[v => !!v || '']"
                        @change="prefectureQuery()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        label="อำเภอ"
                        v-model="addcollege.prefecture_ID"
                        :items="prefectures"
                        item-text="prefecture_name"
                        item-value="prefecture_ID"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md6>
                      <v-autocomplete
                        label="ประเภท"
                        v-model="addcollege.collegetype_ID"
                        :items="collegetypes"
                        item-text="collegetype_name"
                        item-value="collegetype_ID"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        label="ประเภทสถานศึกษาคำนวณอัตรากำลัง"
                        v-model="addcollege.college_id_code_type_manpower"
                        :items="college_type_manpowers"
                        item-text="name_ctm"
                        item-value="id_code_ctm"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-switch
                        v-model="addcollege.collegeDevSW"
                        inset
                        label="สถานศึกษาพัฒนาคุณภาพการศึกษา : ใช่/ไม่ใช่"
                        prepend-icon="mdi-eye"
                      ></v-switch>
                    </v-flex>
                    <v-flex md6> Password : {{ user_passwords }} </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addcollegedialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addcollegeSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletecollegedialog -->
      <v-layout>
        <v-dialog v-model="deletecollegedialog" persistent max-width="40%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลสถานศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletecollegeform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{ editcollege.college_name }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletecollegedialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletecollegeSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editcollegedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editcollegedialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editcollegeform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-text-field
                        label="รหัสสถานศึกษา"
                        v-model="editcollege.college_code"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสถานศึกษา"
                        v-model="editcollege.college_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ชื่อย่อสถานศึกษา"
                        v-model="editcollege.collegeIni"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-autocomplete
                        :items="veivocals"
                        item-text="veiVocalName"
                        item-value="veiVocalID"
                        label="สังกัดสถาบันการอาชีวศึกษา"
                        v-model="editcollege.collegeVei"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        label="จังหวัด"
                        v-model="editcollege.province_ID"
                        :items="provinces"
                        item-text="province_name"
                        item-value="province_ID"
                        required
                        :rules="[v => !!v || '']"
                        @change="prefectureQuery()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        label="อำเภอ"
                        v-model="editcollege.prefecture_ID"
                        :items="prefectures"
                        item-text="prefecture_name"
                        item-value="prefecture_ID"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md6>
                      <v-autocomplete
                        label="ประเภท"
                        v-model="editcollege.collegetype_ID"
                        :items="collegetypes"
                        item-text="collegetype_name"
                        item-value="collegetype_ID"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        label="ประเภทสถานศึกษาคำนวณอัตรากำลัง"
                        v-model="editcollege.college_id_code_type_manpower"
                        :items="college_type_manpowers"
                        item-text="name_ctm"
                        item-value="id_code_ctm"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-switch
                        v-model="editcollege.collegeDevSW"
                        inset
                        label="สถานศึกษาพัฒนาคุณภาพการศึกษา : ใช่/ไม่ใช่"
                        prepend-icon="mdi-eye"
                      ></v-switch>
                    </v-flex>

                    <v-flex md6>
                      <v-radio-group v-model="editcollege.college_vecpro" row>
                        <template v-slot:label>
                          <div>
                            สำนักงานอาชีวศึกษาจังหวัด
                            <strong>เป็น/ไม่เป็น</strong>
                          </div>
                        </template>
                        <v-radio label="เป็น สอจ." value="1"></v-radio>
                        <v-radio label="ไม่เป็น สอจ." value="0"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editcollegedialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editcollegeSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addcollegedialog: false,
      editcollegedialog: false,
      deletecollegedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      colleges: [],
      addcollege: {},
      editcollege: {},
      colleges: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "รหัสสถานศึกษา", align: "left", value: "college_code" },
        { text: "ชื่อสถานศึกษา", align: "left", value: "college_name" },
        { text: "ชื่อย่อ", align: "left", value: "collegeIni" },
        { text: "สถาบันการอาชีวศึกษา", align: "left", value: "veiVocalName" },
        { text: "อำเภอ", align: "left", value: "prefecture_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ประเภท", align: "left", value: "collegetype_name" },
        { text: "สถานศึกษาพัฒนา", align: "left", value: "collegeDev" },
        { text: "สอจ.", align: "left", value: "college_vecpro" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      collegetypes: [],
      collgegs: [],
      collegestatus: [],
      regions: [],
      region_ena: true,
      user_update: {},
      collegeinfo_update: {},
      veivocals: [],
      college_type_manpowers: [],
      addcollegeinfo: {},
      deleteCollegeinfo: {},
      adduser: {},
      user_passwords: [],
      delUser: {}
    };
  },
  async mounted() {
    await this.collegeQueryAll();
    await this.veivocalQueryAll();
    await this.provinceQuery();
    await this.collegetypeQuery();
    await this.college_type_manpowerQuery();
  },

  methods: {
    async collegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.colleges = result.data;
      
    },

    async veivocalQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("veivocal.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.veivocals = result.data;
    },

    async provinceQuery() {
      let result_provin = await this.$http.post("province.php", {
        ApiKey: this.ApiKey
      });
      this.provinces = result_provin.data;
    },

    async prefectureQuery() {
      let result_prefec = await this.$http.post("prefecture.php", {
        ApiKey: this.ApiKey,
        province_ID: this.addcollege.province_ID
      });
      this.prefectures = result_prefec.data;
    },

    async collegetypeQuery() {
      let result_collegety = await this.$http.post("collegetype.php", {
        ApiKey: this.ApiKey
      });
      this.collegetypes = result_collegety.data;
    },

    async college_type_manpowerQuery() {
      let result_collegety = await this.$http.post(
        "college_type_manpower.php",
        {
          ApiKey: this.ApiKey
        }
      );
      this.college_type_manpowers = result_collegety.data;
    },

    async regionQuery() {
      let result_region = await this.$http.post("region.php", {
        ApiKey: this.ApiKey
      });
      this.regions = result_region.data;
    },

    async collegeAdd() {
      this.addcollege = {};
      this.makeid();
      this.addcollegedialog = true;
    },
    async addcollegeSubmit() {
      if (this.$refs.addcollegeform.validate()) {
        this.addcollege.ApiKey = this.ApiKey;
        this.addcollegeinfo.ApiKey = this.ApiKey;
        this.adduser.ApiKey = this.ApiKey;
        this.adduser.user_code = this.addcollege.college_code;
        this.adduser.user_name = this.addcollege.college_code;
        this.adduser.user_password = this.user_passwords;
        this.adduser.user_firstname = this.addcollege.college_name;
        this.adduser.user_lastname = this.addcollege.college_name;
        this.adduser.user_status = "B";

        this.addcollegeinfo.collegeinfo_code = this.addcollege.college_code;

        if (this.addcollege.collegeDevSW == true) {
          this.addcollege.collegeDev = "Dev";
        } else {
          this.addcollege.collegeDev = "";
        }
        let result = await this.$http.post(
          "college.insert.php",
          this.addcollege
        );

        let result_info = await this.$http.post(
          "collegeinfo.insert.php",
          this.addcollegeinfo
        );
        let result_user = await this.$http.post(
          "user.insert.php",
          this.adduser
        );
        if (
          result.data.status == true &&
          result_info.data.status == true &&
          result_user.data.status == true
        ) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.collegeQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.collegeQueryAll();
        }
        this.addcollegedialog = false;
      }
    },

    async collegeEdit(college_ID) {
      let result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey,
        college_ID: college_ID
      });
      this.editcollege = result.data;
      if (this.editcollege.collegeDev == "Dev") {
        this.editcollege.collegeDevSW = true;
      } else {
        this.editcollege.collegeDevSW = false;
      }
      this.editcollegedialog = true;
    },
    async editcollegeSubmit() {
      if (this.$refs.editcollegeform.validate()) {
        this.editcollege.ApiKey = this.ApiKey;

        if (this.editcollege.collegeDevSW == true) {
          this.editcollege.collegeDev = "Dev";
        } else {
          this.editcollege.collegeDev = "";
        }

        this.user_update.ApiKey = this.ApiKey;
        this.user_update.user_code = this.editcollege.college_code;
        this.user_update.user_firstname = this.editcollege.college_name;

        this.collegeinfo_update.ApiKey = this.ApiKey;
        this.collegeinfo_update.collegeinfo_code = this.editcollege.college_code;

        let result = await this.$http.post(
          "college.update.php",
          this.editcollege
        );

        let result_user = await this.$http.post(
          "user.update.code.php",
          this.user_update
        );
        

        let result_collegeinfo = await this.$http.post(
          "collegeinfo.update.code.php",
          this.collegeinfo_update
        );
        

        if (result.data.status == true) {
          this.college = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.collegeQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.collegeQueryAll();
        }
        this.editcollegedialog = false;
      }
    },
    async collegeDelete(college_ID) {
      let result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey,
        college_ID: college_ID
      });
      this.editcollege = result.data;
      this.deletecollegedialog = true;
    },
    async deletecollegeSubmit() {
      if (this.$refs.deletecollegeform.validate()) {
        this.editcollege.ApiKey = this.ApiKey;
        this.deleteCollegeinfo.ApiKey = this.ApiKey;
        this.delUser.ApiKey = this.ApiKey;
        this.delUser.user_code = this.editcollege.college_code;

        this.deleteCollegeinfo.collegeinfo_code = this.editcollege.college_code;

        let result = await this.$http.post(
          "college.delete.php",
          this.editcollege
        );
        let resultCollegeinfo = await this.$http.post(
          "collegeinfo.delete.php",
          this.deleteCollegeinfo
        );
        let resultUser = await this.$http.post(
          "user.college.delete.php",
          this.delUser
        );

        if (
          result.data.status == true &&
          resultCollegeinfo.data.status == true &&
          resultUser.data.status == true
        ) {
          this.college = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.collegeQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletecollegedialog = false;
      }
    },
    async makeid() {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      this.user_passwords = result;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
